import { useMemo, useRef, useState, useEffect } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import copy from "clipboard-copy";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import { AuthenticationResult } from "@azure/msal-browser";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, citationNumber: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    agentType?: string;
    darkMode: boolean;
    chatSessionId?: string;
    idToken?: AuthenticationResult | undefined;
    haveFeedbackOption?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    agentType,
    darkMode,
    chatSessionId,
    idToken,
    haveFeedbackOption
}: Props) => {
    const [renderAsHTML, setRenderAsHTML] = useState(true);
    const codeBlockRegex = /(```[\s\S]*?```)/g;

    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const dataPoints = answer.choices[0].context.data_points;
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(messageContent, dataPoints, isStreaming, agentType, onCitationClicked, renderAsHTML, darkMode),
        [answer, darkMode, renderAsHTML]
    );
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const textRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleCopy = () => {
        if (textRef.current) {
            copy(textRef.current.innerText);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };

    const doesContainCodeBlock = messageContent.match(codeBlockRegex);

    return (
        <Stack className={`${darkMode ? styles.answerContainerDark : styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div className={darkMode ? styles.answerIconDark : styles.answerIcon}>AI:</div>
                    <div className={styles.copyTextContainer}>
                        {doesContainCodeBlock && (
                            <div className={styles.buttonContainer}>
                                <button
                                    className={darkMode ? styles.codeBlockButtonDark : styles.codeBlockButton}
                                    onClick={() => setRenderAsHTML(!renderAsHTML)}
                                >
                                    {renderAsHTML ? "Show Markdown" : "Show HTML"}
                                </button>
                            </div>
                        )}
                        {isCopied && <div className={darkMode ? styles.copyTextDark : styles.copyText}>Kopioitu</div>}
                        <IconButton
                            styles={{
                                root: { color: `${darkMode ? "#FBFBFE" : "black"}` },
                                rootHovered: { backgroundColor: `${darkMode ? "#343541" : "#F05357"}`, color: `${darkMode ? "#FBFBFE" : "black"}` },
                                rootPressed: { backgroundColor: `${darkMode ? "#26262f" : "#F05357"}`, color: `${darkMode ? "#FBFBFE" : "black"}` }
                            }}
                            iconProps={{ iconName: "Copy" }}
                            title="Copy"
                            ariaLabel="Copy"
                            onClick={handleCopy}
                        />
                    </div>
                    {/*> 
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.choices[0].context.thoughts?.length}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.choices[0].context.data_points?.length}
                        />
                    </div>
                    */}
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div ref={textRef} className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>
            {agentType !== "Basic" && !!parsedAnswer.citations.length && (
                <>
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Sitaatit:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path, i)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {!isStreaming && haveFeedbackOption && <FeedbackModal chatSessionId={chatSessionId} idToken={idToken} darkMode={darkMode} />}
        </Stack>
    );
};
