import { useEffect, useRef, useState } from "react";
//import styles from "../../styles/loadingGradient.module.css";
import { UploadedFilesStatus } from "./UploadedFilesStatus";
import styles from "./FileUpload.module.css";
import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import { AlertBox } from "../AlertBox/AlertBox";
import { ToolTip } from "../Button/ToolTip";
import { Button } from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import { getToken, useLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { uploadFile } from "../../api";
import { BoxArrowUp24Filled, BoxArrowUp24Regular } from "@fluentui/react-icons";
import { UploadFilesButton } from "../UploadFilesButton/UploadFilesButton";

interface FileUploadProps {
    isActive: boolean;
    darkMode: boolean;
    agentType: string;
}

export const FileUpload = (props: FileUploadProps) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [successfulFiles, setSuccessfulFiles] = useState<string[]>([]);
    const [failedFiles, setFailedFiles] = useState<string[]>([]);
    const [alreadyInIndex, setAlreadyInIndex] = useState<string[]>([]);
    const [isUploadedFilesModalOpen, setIsUploadedFilesModalOpen] = useState<boolean>(false);
    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const client = useLogin ? useMsal().instance : undefined;

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleStartOver = () => {
        setSelectedFiles(null);
    };

    const handleUpload = async (files: FileList) => {
        if (!files) {
            return;
        }
        const token = client ? await getToken(client) : undefined;
        setIsLoading(true);
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i], files[i].name);
        }
        try {
            const req = await uploadFile(formData, token?.accessToken, props.agentType);

            if (req.status === 200) {
                const res = await req.json();
                setIsLoading(false);
                setIsOpen(false);
                if (res.ok) {
                    const successfulUploads = res.successful;
                    const failedUploads = res.failed;
                    const filesAlredyInIndex = res.alreadyInIndex;
                    setSuccessfulFiles(successfulUploads);
                    setFailedFiles(failedUploads);
                    setAlreadyInIndex(filesAlredyInIndex);
                    return true;
                }
            } else if (req.status === 500) {
                setAlertVisible(true);
                setErrorMessage("Error ingesting file");
                setIsLoading(false);
                return false;
            }
        } catch (e: any) {
            alert(e);
            handleStartOver();
            setIsLoading(false);
        }
    };

    const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;

        try {
            if (files) {
                setSelectedFiles(files);
                await handleUpload(files);
            }
        } catch (error) {
            console.log(error);
            handleStartOver();
        }
    };

    const handleFileButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files) {
            setSelectedFiles(files);
        }

        await handleUpload(files);
    };

    const handeDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const useOutsideClick = (callback: () => void) => {
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    callback();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [callback]);

        return ref;
    };

    const ref = useOutsideClick(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    });

    useEffect(() => {
        const uploadedFilesModal = () => {
            setIsUploadedFilesModalOpen(true);
            const interval = setTimeout(() => setIsUploadedFilesModalOpen(false), 4000);
            return () => clearTimeout(interval);
        };
        if (successfulFiles.length > 0 || alreadyInIndex.length > 0) {
            uploadedFilesModal();
        }
    }, [successfulFiles, failedFiles, alreadyInIndex]);

    useEffect(() => {
        const updateVisibility = () => {
            setAlertVisible(true);
            const timer = setTimeout(() => {
                setAlertVisible(false);
            }, 2000);
            return () => {
                clearTimeout(timer);
            };
        };
        if (alertVisible) {
            updateVisibility();
            setIsOpen(false);
        }
    }, [errorMessage, isLoading]);

    const colorClass = props.isActive ? "text-slate-100" : "text-slate-gray";

    return (
        <div ref={isLoading ? null : ref} className={styles.container}>
            <div className={styles.container2}>
                <div className={styles.buttonContainer}>
                    <UploadFilesButton darkMode={props.darkMode} onClick={handleClick} disabled={isLoading} />
                </div>
                <div>
                    {isUploadedFilesModalOpen && (
                        <UploadedFilesStatus
                            successful={successfulFiles}
                            failed={failedFiles}
                            alreadyInIndex={alreadyInIndex}
                            open={isUploadedFilesModalOpen}
                        />
                    )}
                </div>
                {errorMessage && alertVisible && <AlertBox alert={errorMessage} open={alertVisible} />}

                {isOpen && (
                    <div className={styles.fileuploadContainer}>
                        <div className={props.darkMode ? styles.fileuploadContainer2Dark : styles.fileuploadContainer2}>
                            <div
                                className={`${styles.uploadArea} ${
                                    (isLoading && props.darkMode && styles.loadingGradientDark) || (isLoading && !props.darkMode && styles.loadingGradient)
                                }`}
                                onDrop={handleDrop}
                                onDragOver={handeDragOver}
                            >
                                <input
                                    className={styles.fileInput}
                                    id="file-upload"
                                    type="file"
                                    multiple
                                    accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-,.pdf,.xlsx"
                                    ref={fileInputRef}
                                    onChange={handleFileSelection}
                                />
                                <div
                                    className={props.darkMode ? styles.uploadAreaButtonContainerDark : styles.uploadAreaButtonContainer}
                                    role={"button"}
                                    onClick={handleFileButtonClick}
                                >
                                    <BoxArrowUp24Regular style={props.darkMode ? { color: "#fbfbfe" } : {}} />
                                    <span className={styles.uploadText}>
                                        {!isLoading ? (
                                            <div className={styles.uploadTextDiv}>
                                                Vedä ja pudota tiedosto tai klikkaa ladataksesi <br /> (PDF)
                                            </div>
                                        ) : (
                                            <div className={props.darkMode ? styles.spinnerContainerDark : styles.spinnerContainer}>
                                                <svg fill="none" className={styles.animateSpin} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        clipRule="evenodd"
                                                        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                                                        fill="currentColor"
                                                        fillRule="evenodd"
                                                    />
                                                </svg>
                                                <div>{"Ladataan tiedostoja"}</div>
                                            </div>
                                        )}
                                    </span>
                                    {isLoading && (
                                        <div className={styles.uploadedFiles}>
                                            {selectedFiles &&
                                                Array.from(selectedFiles).map((file, index) => {
                                                    return (
                                                        <div key={index} className={props.darkMode ? styles.fileItemTextDark : styles.fileItemText}>
                                                            <span className="">{file.name}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
