import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";

interface AnswerLoadingProps {
    darkMode: boolean;
}

export const AnswerLoading = ({ darkMode }: AnswerLoadingProps) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={darkMode ? styles.answerContainerDark : styles.answerContainer} verticalAlign="space-between">
                <div className={styles.answerIcon}>AI:</div>
                <Stack.Item grow>
                    <p className={styles.answerText}>
                        Luodaan vastausta
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
