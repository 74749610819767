import { FileTag } from "./FileTag";

interface FilesFieldProps {
    onRemove: (fileName: string) => void;
    fileNames: string[];
}
import styles from "./FilesField.module.css";

export const FilesField = (props: FilesFieldProps) => {
    return (
        <div className={styles.container}>
            <span className={styles.lockedFilesText}>Lukitut tiedostot:</span>
            <div className={styles.lockedFilesContainer}>
                {props.fileNames.map((fileName, index) => (
                    <FileTag key={index} fileName={fileName} onRemove={props.onRemove} />
                ))}
            </div>
        </div>
    );
};
