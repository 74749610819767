import React, { useEffect, useState } from "react";
import styles from "./FeedbackModal.module.css";
import { FaRegFaceMeh, FaRegFaceFrownOpen, FaRegFaceGrin } from "react-icons/fa6";
import { FeedbackData } from "../../types/feedback";
import { AuthenticationResult } from "@azure/msal-browser";
import { sendFeedback } from "../../api";

interface Props {
    chatSessionId: string | undefined;
    idToken: AuthenticationResult | undefined;
    darkMode: boolean;
}

const FeedbackModal: React.FC<Props> = props => {
    const { chatSessionId } = props;
    const { idToken } = props;
    const { darkMode } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedFace, setSelectedFace] = useState<number | null>(null);
    const [faceSelected, setFaceSelected] = useState<boolean>(true);
    const [comment, setComment] = useState<string>("");

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setSelectedFace(null);
        setFaceSelected(true);
    };

    const onSubmit = async () => {
        setIsOpen(!isOpen);
        if (chatSessionId && selectedFace) {
            const data: FeedbackData = {
                chatsession_id: chatSessionId,
                answer_quality: selectedFace,
                comment: comment
            };
            if (idToken) {
                try {
                    sendFeedback(idToken.accessToken, data);
                    console.log("Feedback sent successfully!");
                } catch (error) {
                    console.error("Error sending feedback: ", error);
                }
            }
        }
        setSelectedFace(null);
        setComment("");
    };

    const handleFaceClick = (faceIndex: number) => {
        if (faceIndex === selectedFace) {
            setSelectedFace(null);
            setFaceSelected(true);
        } else {
            setSelectedFace(faceIndex);
            setFaceSelected(false);
        }
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    return (
        <div>
            <button onClick={toggleModal} className={darkMode ? styles.openButtonDark : styles.openButton}>
                Arvioi vastaus
            </button>

            {isOpen && (
                <div className={styles.modalOverlay}>
                    <div className={darkMode ? styles.modalContentDark : styles.modalContent}>
                        <h2>Palaute</h2>
                        <div className={styles.faces}>
                            <div className={`${styles.faceContainer} ${selectedFace === 1 ? styles.selectedFace : ""}`} onClick={() => handleFaceClick(1)}>
                                <FaRegFaceFrownOpen className={`${styles.face} ${styles.face1}`} />
                            </div>
                            <div className={`${styles.faceContainer} ${selectedFace === 2 ? styles.selectedFace : ""}`} onClick={() => handleFaceClick(2)}>
                                <FaRegFaceMeh className={`${styles.face} ${styles.face2}`} />
                            </div>
                            <div className={`${styles.faceContainer} ${selectedFace === 3 ? styles.selectedFace : ""}`} onClick={() => handleFaceClick(3)}>
                                <FaRegFaceGrin className={`${styles.face} ${styles.face3}`} />
                            </div>
                        </div>
                        {selectedFace !== null && (
                            <textarea
                                onChange={handleTextChange}
                                value={comment}
                                placeholder="Kerro meille lisää..."
                                className={darkMode ? styles.textareaDark : styles.textarea}
                            ></textarea>
                        )}
                        <div className={styles.buttonGroup}>
                            <button onClick={toggleModal} className={styles.cancelButton}>
                                Peru
                            </button>
                            <button disabled={faceSelected} className={styles.submitButton} onClick={onSubmit}>
                                Lähetä
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackModal;
