import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import { useLogin } from "../../authConfig";
import { LoginButton } from "../../components/LoginButton";
import { useMsal } from "@azure/msal-react";
import { useDarkMode } from "../../DarkModeContext";
import { useEffect } from "react";
import { Toggle } from "@fluentui/react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import logo from "../../assets/pohjantahti-punainen.png";
import logoWhite from "../../assets/pohjantahti-valkoinen.png";

const Layout = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { darkMode, toggleDarkMode } = useDarkMode();
    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("darkMode");
        } else {
            document.body.classList.remove("darkMode");
        }
    }, [darkMode]);
    if (activeAccount) {
        return (
            <div className={styles.layout}>
                <header className={darkMode ? styles.headerDark : styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="" className={darkMode ? styles.headerTitleContainerDark : styles.headerTitleContainer}>
                            <img src={darkMode ? logoWhite : logo} alt="Pohjantähti logo" className={styles.logo} />
                            <div className={darkMode ? styles.headerTitleDark : styles.headerTitle}> Tekoälyavustajat</div>
                        </Link>
                        <div className={styles.darkmodeToggle}>
                            <div className={styles.iconContainer}>
                                <SunIcon style={{ color: "#EBE8E2" }} />
                            </div>

                            <Toggle checked={darkMode} onChange={toggleDarkMode} />
                            <div className={styles.iconContainer}>
                                <MoonIcon style={darkMode ? {} : { color: "black" }} />
                            </div>
                        </div>
                        {useLogin && <LoginButton darkMode={darkMode} />}
                    </div>
                </header>
                <Outlet />
            </div>
        );
    } else {
        return <Outlet />;
    }
};

export default Layout;
