import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Dropdown, IDropdownOption } from "@fluentui/react";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";
import { chatApi, RetrievalMode, ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, ResponseMessage, chatSecureApi } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { AgentDescription } from "../../components/AgentDescription/AgentDescription";
import { AddAgentButton } from "../../components/AddAgentButton/AddAgentButton";
import { RemoveAgentButton } from "../../components/RemoveAgentButton/RemoveAgentButton";
import { Agent } from "../../types/agent";
import { AgentDropdown } from "../../components/AgentDropdown/AgentDropdown";
import UserFilesModal from "../../components/UserFilesModal/UserFilesModal";
import { FilesField } from "../../components/FilesField/FilesField";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { useDarkMode } from "../../DarkModeContext";
import { v4 as uuidv4 } from "uuid";
import { AuthenticationResult } from "@azure/msal-browser";

type ChatColumnProps = {
    agent: Agent[];
    index: number;
    arrayIndex: number;
    agentCount: number;
    isActive: boolean;
    agentRootRef: React.MutableRefObject<HTMLDivElement | null>;
    add: () => void;
    remove: () => void;
    handleSetActiveAgent: () => void;
    userGroups: string[];
};

const Chat = (props: ChatColumnProps) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(8);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const userGroups = props.userGroups;

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const streamRef = useRef<HTMLDivElement | null>(null);
    const chatRootRef = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const [newIndex, setNewIndex] = useState<number>(props.index);
    const [selectedAgent, setSelectedAgent] = useState<Agent>(props.agent[newIndex]);
    const [selectedDescription, setSelectedDescription] = useState<string>(props.agent[newIndex].description);
    const [lockedFiles, setLockedFiles] = useState<string[]>([]);
    const [citationNumber, setCitationNumber] = useState<number>(0);
    const agents = props.agent;
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const { darkMode, toggleDarkMode } = useDarkMode();
    const [isCompanyAdmin, setIsCompanyAdmin] = useState<boolean>(false);
    const [chatSessionId, setChatSessionId] = useState<string>("");
    const [idToken, setIdToken] = useState<AuthenticationResult | undefined>(undefined);

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(
                    () => {
                        answer += newContent;
                        const latestResponse: ChatAppResponse = {
                            ...askResponse,
                            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                        };
                        setStreamedAnswers([...answers, [question, latestResponse]]);
                        resolve(null);
                    },
                    selectedAgent.model === "gpt-4" ? 140 : 33
                );
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } catch (e) {
            console.error("Error processing event:", e);
            throw e;
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };

    const client = useLogin ? useMsal().instance : undefined;

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        const token = client ? await getToken(client) : undefined;
        setIdToken(token);
        let response: Response | undefined = undefined;
        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                        exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        top: retrieveCount,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: useSuggestFollowupQuestions,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        search_index: selectedAgent.searchIndex,
                        locked_files: lockedFiles,
                        chatsession_id: chatSessionId
                    }
                },
                agent_config: selectedAgent,
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            // NOTE: Agent type is checked and request is sent to the correct endpoint
            if (selectedAgent.type === "Basic") {
                response = await chatSecureApi(request, token?.accessToken);
            } else {
                response = await chatApi(request, token?.accessToken);
            }

            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
                setAnswers([...answers, [question, parsedResponse]]);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        setChatSessionId(uuidv4());
    };

    const handleAddAgent = () => {
        props.add();
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    useEffect(() => {
        if (autoScroll) {
            streamRef.current?.scrollBy({ top: 1000, behavior: "smooth" });
        }
    }, [streamedAnswers, autoScroll]);

    const handleScroll = () => {
        if (streamRef.current && chatRootRef.current) {
            const { scrollTop: scrollYPosition, scrollHeight: scrollYHeight, clientHeight: chatRootHeight } = streamRef.current;
            const scrollFromBottom = scrollYHeight - scrollYPosition;
            const isBottomOfContainer = scrollFromBottom <= chatRootHeight + 20;

            setAutoScroll(isBottomOfContainer);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const handleSelectedFiles = (selectedFiles: string[]) => {
        setLockedFiles(selectedFiles);
    };

    const handleRemoveFile = (fileName: string) => {
        setLockedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
    };

    const onShowCitation = (citation: string, index: number, citationNumber: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
            setCitationNumber(citationNumber - 1);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const handleSelectedAgentIndex = (selectedNameIndex: number) => {
        setNewIndex(selectedNameIndex);
        setSelectedAgent(props.agent[selectedNameIndex]);
        setSelectedDescription(props.agent[selectedNameIndex].description);
        clearChat();
    };

    useEffect(() => {
        const adminEmails = [
            "jukka.gronroos@pohjantahti.fi",
            "liisa-maria.koivuniemi@pohjantahti.fi",
            "josua.kiviranta@innofactor.com",
            "CtollaAD@pohjantahti.fi",
            "ctollaad@pohjantahti.fi"
        ]; // PUT ADMIN EMAILS HERE (SHOULD BE REFACTORED)
        const account = client?.getActiveAccount();
        if (account) {
            setIsCompanyAdmin(adminEmails.includes(account.username.toLowerCase()));
        }
        setChatSessionId(uuidv4());
    }, []);

    return (
        <div ref={chatRootRef} className={darkMode ? styles.containerDark : styles.container}>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    <div className={`${darkMode ? styles.commandsContainerDark : styles.commandsContainer} ${props.isActive ? "active" : ""}`}>
                        <div className={styles.dropdownContainer}>
                            <AgentDropdown
                                darkMode={darkMode}
                                default={selectedAgent.name}
                                options={agents}
                                onSelect={handleSelectedAgentIndex}
                                userGroups={userGroups}
                                isAdmin={isCompanyAdmin}
                            />
                            {selectedAgent.type === "FileReader" && (
                                <>
                                    <FileUpload darkMode={darkMode} isActive={true} agentType={selectedAgent.type} />
                                    <UserFilesModal
                                        darkMode={darkMode}
                                        onSelect={handleSelectedFiles}
                                        lockedFiles={lockedFiles}
                                        isActive={false}
                                        agentType={selectedAgent.type}
                                    />
                                </>
                            )}
                            {(selectedAgent.type === "Azure" || selectedAgent.type === "Compensation") && (
                                <>
                                    <UserFilesModal
                                        darkMode={darkMode}
                                        onSelect={handleSelectedFiles}
                                        lockedFiles={lockedFiles}
                                        isActive={false}
                                        agentType={selectedAgent.type}
                                        showDeleteButton={false}
                                    />
                                </>
                            )}
                        </div>
                        <div className={styles.buttonsContainer}>
                            <ClearChatButton
                                className={styles.commandButton}
                                onClick={clearChat}
                                disabled={!lastQuestionRef.current || isLoading}
                                darkMode={darkMode}
                            ></ClearChatButton>
                            <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} darkMode={darkMode} />
                            <AddAgentButton className={styles.commandButton} onClick={handleAddAgent} darkMode={darkMode} />
                            <RemoveAgentButton className={styles.commandButton} onClick={props.remove} darkMode={darkMode} />
                        </div>
                    </div>
                    {lockedFiles.length > 0 && selectedAgent.type === "FileReader" && (
                        <FilesField onRemove={handleRemoveFile} fileNames={lockedFiles}></FilesField>
                    )}
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <AgentDescription darkMode={darkMode} agentName={selectedAgent.name} agentDescription={selectedDescription} />
                            {/*<ExampleList onExampleClicked={onExampleClicked} />*/}
                        </div>
                    ) : (
                        <div onScroll={handleScroll} className={darkMode ? styles.chatMessageStreamDark : styles.chatMessageStream} ref={streamRef}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index}>
                                        <UserChatMessage darkMode={darkMode} message={streamedAnswer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                key={index}
                                                answer={streamedAnswer[1]}
                                                isSelected={false}
                                                onCitationClicked={(c, i) => onShowCitation(c, index, citationNumber)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                agentType={selectedAgent.type}
                                                darkMode={darkMode}
                                                idToken={idToken}
                                                chatSessionId={chatSessionId}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage darkMode={darkMode} message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={(c, citationNumber) => onShowCitation(c, index, citationNumber)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                agentType={selectedAgent.type}
                                                darkMode={darkMode}
                                                idToken={idToken}
                                                chatSessionId={chatSessionId}
                                                haveFeedbackOption={selectedAgent.haveFeedbackOption}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage darkMode={darkMode} message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading darkMode={darkMode} />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage darkMode={darkMode} message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={darkMode ? styles.chatInputDark : styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            darkMode={darkMode}
                            placeholder="Lähetä viesti"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                        citationNumber={citationNumber}
                        agent={selectedAgent}
                        darkMode={darkMode}
                        client={client}
                    />
                )}

                <Panel
                    styles={{
                        main: { backgroundColor: `${darkMode ? "#26262f" : "white"}`, color: `${darkMode ? "#fbfbfe" : ""}` },
                        header: { backgroundColor: `${darkMode ? "#26262f" : "white"}` },
                        headerText: { color: `${darkMode ? "#fbfbfe" : ""}` },
                        commands: { backgroundColor: `${darkMode ? "#26262f" : "white"}` },
                        footer: { backgroundColor: `${darkMode ? "#26262f" : "white"}` }
                    }}
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        styles={{ subComponentStyles: { label: { root: { color: `${darkMode ? "#fbfbfe" : ""}` } } } }}
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        styles={{ label: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField
                        styles={{ subComponentStyles: { label: { root: { color: `${darkMode ? "#fbfbfe" : ""}` } } } }}
                        className={styles.chatSettingsSeparator}
                        label="Exclude category"
                        onChange={onExcludeCategoryChanged}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                    {useLogin && (
                        <Checkbox
                            styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter}
                            label="Use oid security filter"
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin && (
                        <Checkbox
                            styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter}
                            label="Use groups security filter"
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}
                    <Dropdown
                        styles={{ label: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                            { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                            { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                    {/*useLogin && <TokenClaimsDisplay darkMode={darkMode} />*/}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
