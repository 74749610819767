import { useEffect, useRef, useState } from "react";
import styles from "./AgentDropdown.module.css";
import { Agent } from "../../types/agent";

type DropdownProps = {
    options: Agent[];
    default: string;
    onSelect: (index: number) => void;
    darkMode: boolean;
    userGroups: string[];
    isAdmin: boolean;
};

export const AgentDropdown = (props: DropdownProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>(props.default);
    const userGroups = props.userGroups;
    const handleButtonClick = () => {
        setOpen(!open);
    };
    const ref = useOutsideClick(() => {
        if (open) {
            setOpen(false);
        }
    });

    const handleSelect = (option: string) => {
        setSelectedOption(option);
        setOpen(false);
    };

    const showOption = (permissiongroup: string | undefined): boolean => {
        if (userGroups.some(group => group === permissiongroup) || props.isAdmin) {
            return true;
        } else if (permissiongroup === undefined || permissiongroup === "") {
            return true;
        }
        return false;
    };

    return (
        <div ref={ref} className="">
            <button onClick={handleButtonClick} className={props.darkMode ? styles.dropdownButtonDark : styles.dropdownButton}>
                {selectedOption}
                {/*<img src="/dropdown-arrow.svg" alt="remove icon" className={styles.icon}></img>*/}
            </button>
            {open && (
                <div className={props.darkMode ? styles.dropdownContainerDark : styles.dropdownContainer}>
                    {props.options.map((option, index) => (
                        <div key={index}>
                            {showOption(option?.permissionGroup) && (
                                <div
                                    key={index}
                                    className={props.darkMode ? styles.dropdownItemDark : styles.dropdownItem}
                                    onClick={() => {
                                        handleSelect(option.name);
                                        props.onSelect(index);
                                    }}
                                >
                                    <span className="">
                                        <span className={styles.agentNameText}>{option.name}</span>
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback]);

    return ref;
};
