import styles from "./AgentDescription.module.css";

type AgentDescriptionProps = {
    agentName: string;
    agentDescription: string;
    darkMode: boolean;
};

export const AgentDescription = (props: AgentDescriptionProps) => {
    return (
        <div className={styles.descriptionContainer}>
            <div className={props.darkMode ? styles.descriptionCardDark : styles.descriptionCard}>
                <div className={styles.descriptionTitle}>{props.agentName}</div>
                <div className={styles.descriptionText} dangerouslySetInnerHTML={{ __html: props.agentDescription }} />
            </div>
        </div>
    );
};
