import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { DisplayPdf } from "./PdfViewer";
import { ParsedSupportingContentItem, parseSupportingContentItem } from "./SupportingContentParser";
import { useState } from "react";
import { Agent } from "../../types/agent";
import { IPublicClientApplication } from "@azure/msal-browser";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatAppResponse;
    citationNumber: number;
    agent: Agent;
    darkMode: boolean;
    client: IPublicClientApplication | undefined;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({
    answer,
    activeTab,
    activeCitation,
    citationHeight,
    className,
    onActiveTabChanged,
    citationNumber,
    agent,
    darkMode,
    client
}: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.choices[0].context.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.choices[0].context.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const sanitizedThoughts = DOMPurify.sanitize(answer.choices[0].context.thoughts!);

    let link = "";
    if (activeCitation) {
        link = activeCitation;
    }
    const extractedPath: string | undefined = link.split("/")[2];
    const citations = answer.choices[0].context.data_points.map(citation => {
        const parsed = parseSupportingContentItem(citation);
        console.log("Parsed pageNumber: ", parsed.pageNumber);
        return parsed;
    });
    const clickedCitation = citations.filter(citation => citation.title === extractedPath);
    const pivotStyles: IPivotStyles = {
        root: [
            {
                backgroundColor: "#171717"
            }
        ],
        link: [
            {
                color: "#fbfbfe"
            }
        ],
        linkIsSelected: [
            {
                color: "#fbfbfe;",
                backgroundColor: "#26262f",
                fontWeight: 800,
                borderBottom: "2px solid #000",
                selectors: {
                    ":before": {
                        borderBottom: "none"
                    }
                }
            }
        ],
        linkContent: undefined,
        text: undefined,
        count: undefined,
        icon: undefined,
        linkInMenu: undefined,
        overflowMenuButton: undefined
    };

    return (
        <Pivot
            styles={darkMode ? pivotStyles : {}}
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Ajatusprosessi"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={darkMode ? styles.thoughtProcessDark : styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Tukisisältö"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent darkMode={darkMode} supportingContent={answer.choices[0].context.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Sitaatti"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {activeCitation && clickedCitation.length > 0 && (
                    <DisplayPdf
                        onClose={() => onActiveTabChanged(activeTab)}
                        activeCitation={activeCitation}
                        citationPage={clickedCitation[0].pageNumber}
                        sourceDocs={{
                            pageContent: clickedCitation[0].content,
                            metadata: {
                                documentName: "",
                                source: "",
                                pageNumber: clickedCitation[0].pageNumber
                            }
                        }}
                        agent={agent}
                        darkMode={darkMode}
                        client={client}
                    />
                )}
            </PivotItem>
        </Pivot>
    );
};
