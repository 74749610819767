import { Delete24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./FilesField.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const RemoveButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.buttonContainer} ${className ?? ""}`}>
            <div className={styles.tooltip}>
                <Button icon={<Delete24Regular />} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
};
