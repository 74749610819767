import { useEffect, useRef, useState } from "react";
import { Agent } from "../../types/agent";
import Chat from "../chat/Chat";
import styles from "./Agents.module.css";
import { getUserGroups, useLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const Agents = () => {
    const [columnPlacementIndex, setColumnPlacementIndex] = useState<number>(0);
    const [columnId, setColumnId] = useState<number>(3);
    const agentRootRef = useRef<HTMLDivElement | null>(null);
    const agentsRef = useRef<HTMLDivElement | null>(null);
    const [userGroups, setUserGroups] = useState<string[]>([]);
    const [chatColumns, setChatColumns] = useState<{ number: number; id: number }[]>([
        {
            number: 0,
            id: 0
        }
    ]);
    const agents: Agent[] = [
        {
            id: "018ff190-5265-7526-810c-718d7ebdd6bb",
            type: "Basic",
            name: "Yleinen avustaja",
            apiEndpoint: "/secure_chat",
            description: "Juttele tietoturvallisesti ChatGPT:lle.",
            model: "gpt-4o",
            permissionGroup: "",
            haveFeedbackOption: false
        },
        {
            id: "018ff190-5265-71b1-b604-32968535349d",
            type: "FileReader",
            name: "Omat dokumentit avustaja",
            apiEndpoint: "/chat",
            searchIndex: "user-document-index",
            description: `Käytä rohkeasti apuna Omien dokumenttien tekoälyavustajaa, kun haluat apua tiedon tulkintaan, kokoamiseen, etsimiseen tai tiivistämiseen. <br/> Lataa aineistoksi pdf-tiedosto.`,
            model: "gpt-4o",
            permissionGroup: "",
            haveFeedbackOption: false
        },
        {
            id: "018ff190-5265-7526-810c-718d7ebdd6bb",
            type: "Compensation",
            name: "Korvausavustaja",
            apiEndpoint: "/chat",
            searchIndex: "compensationindex-2",
            description: `Käytä rohkeasti apuna tekoälyavustajaa. <br /> Laita kysymyksen alkuun ehtokoodi (esim. AJ600), johon kysymyksesi liittyy.`,
            model: "gpt-4o",
            permissionGroup: "86f57d8f-0dd6-49f3-8a8f-8705d29a7341",
            haveFeedbackOption: true
        },
        {
            id: "018ff190-5265-7526-810c-718d7ebdd6ba",
            type: "Compensation",
            name: "Korvausavustaja testi",
            apiEndpoint: "/chat",
            searchIndex: "compensationindex-2",
            description: `Testaa tekoälyavustajaa. <br /> Laita kysymyksen alkuun ehtokoodi (esim. AJ600), johon kysymyksesi liittyy.`,
            model: "gpt-4o",
            permissionGroup: "a021d4e0-cd23-493b-a609-b6fa24976859",
            haveFeedbackOption: true
        }
    ];

    const client = useLogin ? useMsal().instance : undefined;
    useEffect(() => {
        const fetchUserGroups = async () => {
            if (userGroups.length === 0) {
                const groups = await getUserGroups(client);
                setUserGroups(groups ?? []);
            }
        };

        fetchUserGroups();
    }, [userGroups.length]);

    const handleAdd = (index: number) => {
        setColumnPlacementIndex(index);
        setColumnId(columnId + 1);
        const newID = columnId + 1;
        const newColumn = {
            number: newID <= 2 ? newID : 0,
            id: newID
        };
        setChatColumns(prevChatColumns => {
            const updatedColumns = [...prevChatColumns];
            updatedColumns.splice(index, 0, newColumn);
            return updatedColumns;
        });

        if (agentsRef.current) {
            const addedAgentElement = agentsRef.current.children[index];
            const isLast = index === agentsRef.current.children.length;

            if (isLast) {
                setTimeout(() => {
                    const addedAgentElement = agentsRef.current?.children[index];
                    addedAgentElement?.scrollIntoView({ behavior: "smooth", block: "end" });
                }, 500);
            } else {
                addedAgentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }
    };

    const handleRemove = (columnToRemove: number) => {
        if (chatColumns.length > 1) {
            const updatedColumns = chatColumns.filter(column => column.id !== columnToRemove);
            setChatColumns(updatedColumns);
        }
    };

    return (
        <div ref={agentRootRef} className={styles.container}>
            <div ref={agentsRef} className={styles.agentsContainer}>
                {chatColumns.map((agent, index) => (
                    <Chat
                        key={agent.id}
                        agent={agents}
                        index={agent.number}
                        add={() => handleAdd(index + 1)}
                        remove={() => handleRemove(agent.id)}
                        arrayIndex={index}
                        agentRootRef={agentRootRef}
                        agentCount={chatColumns.length}
                        isActive={false}
                        handleSetActiveAgent={() => console.log("active")}
                        userGroups={userGroups}
                    />
                ))}
            </div>
        </div>
    );
};

export default Agents;
