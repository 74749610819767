import { DocumentArrowUp20Regular, WrenchScrewdriver24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./UploadFilesButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const UploadFilesButton = ({ className, disabled, onClick, darkMode }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext}>Lataa tiedostoja</span>
                <Button style={darkMode ? { color: "#fbfbfe" } : {}} icon={<DocumentArrowUp20Regular />} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
};
