export type ParsedSupportingContentItem = {
    title: string;
    content: string;
    pageNumber: number;
};

export function parseSupportingContentItem(item: string): ParsedSupportingContentItem {
    // Assumes the item starts with the file name followed by : and the content.
    // Example: "sdp_corporate.pdf: this is the content that follows".
    const parts = item.split(": ");
    const title = parts[0];
    const content = parts.slice(1).join(": ");
    const lastEqualIndex = title.lastIndexOf("=");
    const pageParam = title.slice(lastEqualIndex + 1);

    // Convert the extracted string to a number
    const pageNumber: number = parseInt(pageParam, 10);

    return {
        title,
        content,
        pageNumber
    };
}
